import React, { useState, createContext } from "react";

const HeaderContextProvider = ({ children }) => {
    const [section, setSection] = useState(null);

    return (
        <>
            <HeaderContext.Provider value={{ section, setSection }}>
                {children}
            </HeaderContext.Provider>
        </>
    );
};

const initialState = {
    section: null,
    setSection: () => {},
};

export const HeaderContext = createContext(initialState);
export default HeaderContextProvider;
