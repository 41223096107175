import React from "react";
import HeaderContextProvider from "./src/contexts/HeaderContext";
import BlockUI from "./src/contexts/BlockUIContext";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./src/styles/Main.css";

export const wrapRootElement = ({ element }) => (
    <BlockUI>
        <HeaderContextProvider>
            {element}
        </HeaderContextProvider>
    </BlockUI>
);