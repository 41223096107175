import React, { useState, createContext } from "react";
import "@base/styles/BlockUI.css";
import spinnerImage from '@static/images/animations/spinner1.svg';

const BlockUIContextProvider = ({ children }) => {
    const [blocking, setBlocking] = useState(false);
    const [, setSpinner] = useState(3);
    const [message, setMessage] = useState(null);

    return (
        <>
            <BlockUIContext.Provider
                value={{
                    setBlocking,
                    blocking,
                    setSpinner,
                    setMessage,
                    message,
                }}
            >
                <>
                    {blocking && (
                        <div className="blockui">
                            <div className="mx-auto my-auto">
                                <img
                                    className={`inline-block text-white`}                                    
                                    src={spinnerImage}
                                    alt="Loading..."
                                />
                                {message && (
                                    <span className="text">{message}</span>
                                )}
                            </div>
                        </div>
                    )}
                    {children}
                </>
            </BlockUIContext.Provider>
        </>
    );
};

const initialState = {
    blocking: false,
    spinner: 1,
    message: null,
    setBlocking: () => { },
    setSpinner: () => { },
    setMessage: () => { },
};

export const BlockUIContext = createContext(initialState);
export default BlockUIContextProvider;
